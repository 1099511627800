/**
 * api接口统一管理
 */
import {get, post} from './http'
import current from "element-ui/packages/table/src/store/current";
// staff
export const login = (id, password) => post('/staff/login', {id, password})
export const showStaffById =(id) => post('/staff/showStaffById',{id})
export const changeName = (name) => post('/staff/changeName', {name})
export const checkLogin = () => get('/staff/checkLogin')
export const changeOrganizationId = (id, organizationId) => post('/staff/changeOrganizationId', {id, organizationId})
export const changePassword = (id, oldPassword, newPassword) => post('/staff/changePassword', {id, oldPassword, newPassword})
export const changeStatus = (id, status) => post('/staff/changeStatus', {id, status})
export const changeValidity = (id, validity) => post('/staff/changeValidity', {id, validity})
export const createStaff = (name, organizationId, status, validity) => post('/staff/createStaff', {name, organizationId, status, validity})
export const removeStaffById = (id) => post('/staff/removeStaffById', {id})
export const resetPassword = (id) => post('/staff/resetPassword', {id})
export const showStaffsByLikeName = (likeName, currentPage, pageSize) => post('/staff/showStaffsByLikeName', {likeName, currentPage, pageSize})
export const showUsageInStaffByAuthorityId = (authorityId, currentPage, pageSize) => post('/staff/showUsageInStaffByAuthorityId', {authorityId, currentPage, pageSize})
export const showStaffsByOrganizationId = (organizationId, currentPage, pageSize) => post('/staff/showStaffsByOrganizationId', {organizationId, currentPage, pageSize})
export const showUsageInStaffByRoleId = (roleId, currentPage, pageSize) => post('/staff/showUsageInStaffByRoleId', {roleId, currentPage,pageSize})
//area
export const changeLevel =(id,level) => post('/area/changeLevel',{id,level})
export const changeAreaName = (id,name) => post('/area/changeName',{id,name})
export const createArea = (name,level,superiorId) => post('/area/createArea',{name,level,superiorId})
export const removeAreaById = (id) => post('/area/removeAreaById',{id})
export const showAreaById = (id) => post('/area/showAreaById',{id})
export const showAreasByLevel = (level,currentPage,pageSize) => post('/area/showAreasByLevel',{level,currentPage,pageSize})
export const showAreasByLikeName = (likeName,currentPage,pageSize) => post('/area/showAreasByLikeName',{likeName,currentPage,pageSize})
export const showAreasBySuperiorId = (superiorId,currentPage,pageSize) => post('/area/showAreasBySuperiorId',{superiorId,currentPage,pageSize})
//authority
export const changeAuthorityName = (id,name) => post('/authority/changeName',{id,name})
export const changeAuthorityOwnerId = (id,ownerId) => post('/authority/changeOwnerId',{id,ownerId})
export const checkAuthority = (id) => post('/authority/checkAuthority',{id})
export const createAuthority = (name) => post('/authority/createAuthority',{name})
export const grantAuthority = (id,staffId) => post('/authority/grantAuthority',{id,staffId})
export const removeAuthority = (id) => post('/authority/removeAuthority',{id})
export const revokeAuthority = (id,staffId) => post('/authority/revokeAuthority',{id,staffId})
export const showAuthoritiesOwnedByMe = (currentPage,pageSize) => post('/authority/showAuthoritiesOwnedByMe',{currentPage,pageSize})
export const showAuthorityById = (id) => post('/authority/showAuthorityById',{id})
export const showMyAuthorities = () => get('/authority/showMyAuthorities')
export const showAuthoritiesGrantedByMeByGranteeId = (granteeId,currentPage,pageSize) =>post('/authority/showAuthoritiesGrantedByMeByGranteeId',{granteeId,currentPage,pageSize})
export const showGranteesGrantedByMeByAuthorityId =(authorityId,currentPage,pageSize) => post('/authority/showGranteesGrantedByMeByAuthorityId',{authorityId,currentPage,pageSize})
//organization
export const changeAreaId = (id,areaId) => post('/organization/changeAreaId',{id,areaId})
export const changeOrganizationName = (id,name) => post('/organization/changeName',{id,name})
export const changeOwnerId = (id,ownerId) => post('/organization/changeOwnerId',{id,ownerId})
export const changeSuperiorId = (id,superiorId) => post('/organization/changeSuperiorId',{id,superiorId})
export const changeType = (id,type) => post('/organization/changeType',{id,type})
export const createOrganization = (name,areaId,superiorId,type) => post('/organization/createOrganization',{name,areaId,superiorId,type})
export const removeOrganizationById = (id) => post('/organization/removeOrganizationById',{id})
export const showMyOrganizations = (currentPage,pageSize) => post('/organization/showMyOrganizations',{currentPage,pageSize})
export const showOrganizationById = (id) => post('/organization/showOrganizationById',{id})
export const showOrganizationByName = (name) => post('/organization/showOrganizationByName',{name})
export const showOrganizationsByAreaId = (areaId,currentPage,pageSize) => post('/organization/showOrganizationsByAreaId',{areaId,currentPage,pageSize})
export const showOrganizationsBySuperiorId = (superiorId,currentPage,pageSize) => post('/organization/showOrganizationsBySuperiorId',{superiorId,currentPage,pageSize})
export const showOrganizationsByType = (type,currentPage,pageSize) => post('/organization/showOrganizationsByType',{type,currentPage,pageSize})
//role
export const addAuthorityToRole = (roleId,authorityId) => post('/role/addAuthorityToRole',{roleId,authorityId})
export const changeRoleName = (id,name) => post('/role/changeName',{id,name})
export const createRole = (name) => post('/role/createRole',{name})
export const grantRole = (roleId,staffId) => post('/role/grantRole',{roleId,staffId})
export const removeAuthorityFromRole = (roleId,authorityId) => post('/role/removeAuthorityFromRole',{roleId,authorityId})
export const removeRoleById = (id) => post('/role/removeRoleById',{id})
export const revokeRole = (roleId,staffId,cascadeOrChangeGranter) => post('/role/revokeRole',{roleId,staffId,cascadeOrChangeGranter})
export const showAuthoritiesByRoleId = (roleId) => post('/role/showAuthoritiesByRoleId',{roleId})
export const showGranteesByRoleId = (roleId,currentPage,pageSize) => post('/role/showGranteesByRoleId',{roleId,currentPage,pageSize})
export const showMyRoles = () => get('/role/showMyRoles')
export const showGranteesGrantedByMeByRoleId =(roleId,currentPage,pageSize)=>post('/role/showGranteesGrantedByMeByRoleId',{roleId,currentPage,pageSize})
export const showRoleById = (id) => post('/role/showRoleById',{id})
export const showRolesOwnedByMe = () => get('/role/showRolesOwnedByMe')
export const showUsageInRoleByAuthorityId = (authorityId) => post('/role/showUsageInRoleByAuthorityId',{authorityId})

//communication
export const addCommunicationReceiver = (communicationId,receiverId) => post('/communication/addCommunicationReceiver', {communicationId,receiverId})
export const changeContent = (id, content) => post('/communication/changeContent', {id, content})
export const changeTitle = (id,title) => post('/communication/changeTitle', {id,title})
// export const changeType = (id,type) => post('/communication/changeType', {id,type})
export const createCommunication = (title, type,content) => post('/communication/createCommunication', {title, type,content})
export const readCommunication = (communicationId) => post('/communication/readCommunication', {communicationId})
export const removeCommunicationById = (id) => post('/communication/removeCommunicationById',{id})
export const removeCommunicationReceiver = (communicationId,receiverId) => post('/communication/removeCommunicationReceiver', {communicationId,receiverId})
export const showCommunicationById = (id) => post('/communication/showCommunicationById', {id})
export const showCommunicationsOriginatedByMe = (currentPage, pageSize) => post('/communication/showCommunicationsOriginatedByMe', {currentPage,pageSize})
export const showCommunicationsOriginatedByMeByLikeTitle = (likeTitle,currentPage,pageSize) => post('/communication/showCommunicationsOriginatedByMeByLikeTitle', {likeTitle,currentPage,pageSize})
export const showCommunicationsOriginateByMeByType = (type,currentPage,pageSize) => post('/communication/showCommunicationsOriginatedByMeByType', {type,currentPage,pageSize})
export const showReceivedCommunications = (unreadOnly,currentPage,pageSize) => post('/communication/showReceivedCommunications',{unreadOnly,currentPage,pageSize})
export const showReceivedCommunicationsByLikeTitle = (likeTitle,unreadOnly,currentPage,pageSize) => post('/communication/showReceivedCommunicationsByLikeTitle', {likeTitle,unreadOnly,currentPage,pageSize})
export const showReceivedCommunicationsByType = (type, unreadOnly,currentPage,pageSize) => post('/communication/showReceivedCommunicationsByType', {type, unreadOnly,currentPage,pageSize})
export const showReceiversByCommunicationId = (communicationId,currentPage,pageSize) => post('/communication/showReceiversByCommunicationId',{communicationId,currentPage,pageSize})
export const showChatBetweenTarget = (target) => post('/communication/showChatBetweenTarget',{target})
//message
export const readMessage = (id) => post('/message/readMessage', {id})
export const removeMessage = (id) => post('/message/removeMessage', {id})
export const sendMessage = (type,level,content,validity,format,source,receiverId) => post('/message/sendMessage',{type,level,content,validity,format,source,receiverId})
export const showMessageById = (id) => post('/message/showMessageById', {id})
export const showMyInbox = (currentPage,pageSize) => post('/message/showMyInbox', {currentPage,pageSize})
export const showMyOutbox = (currentPage,pageSize) => post('/message/showMyOutbox',{currentPage,pageSize})

//log
export const showMyOperationLogs =(currentPage,pageSize)=>post('/operationLog/showMyOperationLogs',{currentPage,pageSize})
export const showOperationLogById = (id) => post('/operationLog/showOperationLogById',{id})
export const showOperationLogsByOrganizationId = (organizationId,currentPage,pageSize) => post('/operationLog/showOperationLogsByOrganizationId',{organizationId,currentPage,pageSize})
export const showOperationLogsFromOrganizationsOwnedByMe = (currentPage,pageSize) => post('/operationLog/showOperationLogsFromOrganizationsOwnedByMe',{currentPage,pageSize})

//Notice
export const createNotice =(title,keyword,type,urgency,publishTime,content)=>post('/notice/createNotice',{title,keyword,type,urgency,publishTime,content})
export const showNoticesPublishedByMe =(currentPage,pageSize)=>post('/notice/showNoticesPublishedByMe',{currentPage,pageSize})
export const addNoticeAudience = (noticeId,audienceId) => post('/notice/addNoticeAudience', {noticeId,audienceId})
export const changeNoticeContent = (id,content) => post('/notice/changeContent',{id,content})
export const changeKeyword = (id,keyword) => post('/notice/changeKeyword',{id,keyword})
export const changeNoticeAttachmentName = (id,name) => post('/notice/changeNoticeAttachmentName',{id,name})
export const changeNoticeAttachmentSrc = (id,src) => post('/notice/changeNoticeAttachmentSrc',{id,src})
export const changeNoticeAttachmentType = (id,type) => post('/notice/changeNoticeAttachmentType',{id,type})
export const changePublishTime = (id,publishTime) => post('/notice/changePublishTime', {id,publishTime})
export const changeNoticeTitle = (id,title) => post('/notice/changeTitle',{id,title})
export const changeNoticeType = (id,type) => post('/notice/changeType',{id,type})
export const changeUrgency = (id,urgency) => post('/notice/changeUrgency',{id,urgency})
export const createNoticeAttachment = (noticeId,name,type,src) => post('/notice/createNoticeAttachment',{noticeId,name,type,src})
export const makeComment = (noticeId,content) => post('/notice/makeComment',{noticeId,content})
export const readNotice = (noticeId) => post('/notice/readNotice', {noticeId})
export const removeNoticeAttachmentById = (id) => post('/notice/removeNoticeAttachmentById', {id})
export const removeNoticeAudience = (noticeId,audienceId) => post('/notice/removeNoticeAudience',{noticeId,audienceId})
export const removeNoticeById = (id) => post('/notice/removeNoticeById',{id})
export const removeNoticeCommentById = (id) => post('/notice/removeNoticeCommentById',{id})
export const showNoticeAttachmentById = (id) => post('/notice/showNoticeAttachmentById',{id})
export const showNoticeAttachmentsByNoticeId = (noticeId,currentPage,pageSize) =>post('/notice/showNoticeAttachmentsByNoticeId',{noticeId,currentPage,pageSize})
export const showNoticeAttachmentsByNoticeIdAndLikeName = (noticeId,likeName,currentPage,pageSize) => post('/notice/showNoticeAttachmentsByNoticeIdAndLikeName',{noticeId,likeName,currentPage,pageSize})
export const showNoticeAttachmentsByNoticeIdAndType = (noticeId,type,currentPage,pageSize) => post('/notice/showNoticeAttachmentsByNoticeIdAndType',{noticeId,type,currentPage,pageSize})
export const showNoticeById = (id) => post('/notice/showNoticeById', {id})
export const showNoticeCommentById = (id) => post('/notice/showNoticeCommentById',{id})
export const showNoticeCommentsByNoticeId = (noticeId,currentPage,pageSize) => post('/notice/showNoticeCommentsByNoticeId',{noticeId,currentPage,pageSize})
export const showNoticeCommentsCommentedByMe = (currentPage,pageSize) => post('/notice/showNoticeCommentsCommentedByMe',{currentPage,pageSize})
export const showNoticesPublishedByMeByKeyword = (keyword,currentPage,pageSize) => post('/notice/showNoticesPublishedByMeByKeyword',{keyword,currentPage,pageSize})
export const showNoticesPublishedByMeByLikeTitle = (likeTitle,currentPage,pageSize) => post('/notice/showNoticesPublishedByMeByLikeTitle', {likeTitle,currentPage,pageSize})
export const showNoticesPublishedByMeByType = (type,currentPage,pageSize) => post('/notice/showNoticesPublishedByMeByType',{type,currentPage,pageSize})
export const showNoticesPublishedByMeOrderByPublishTime = (isAsc,currentPage,pageSize) => post('/notice/showNoticesPublishedByMeOrderByPublishTime',{isAsc,currentPage,pageSize})
export const showNoticesPublishedByMeOrderByUrgency = (isAsc,currentPage,pageSize) => post('/notice/showNoticesPublishedByMeOrderByUrgency',{isAsc,currentPage,pageSize})
export const showNoticesToMe = (unreadOnly,currentPage,pageSize) => post('/notice/showNoticesToMe',{unreadOnly,currentPage,pageSize})
export const showNoticesToMeByKeyword = (keyword,unreadOnly,currentPage,pageSize) => post('/notice/showNoticesToMeByKeyword',{keyword,unreadOnly,currentPage,pageSize})
export const showNoticesToMeByLikeTitle = (likeTitle,unreadOnly,currentPage,pageSize) => post('/notice/showNoticesToMeByLikeTitle',{likeTitle,unreadOnly,currentPage,pageSize})
export const showNoticesToMeByType = (type,unreadOnly,currentPage,pageSize) => post('/notice/showNoticesToMeByType',{type,unreadOnly,currentPage,pageSize})
export const showNoticesToMeOrderByPublishTime = (isAsc,unreadOnly,currentPage) => post('/notice/showNoticesToMeOrderByPublishTime',{isAsc,unreadOnly,currentPage})
export const showNoticesToMeOrderByUrgency = (isAsc,unreadOnly,currentPage,pageSize) => post('/notice/showNoticesToMeOrderByUrgency',{isAsc,unreadOnly,currentPage,pageSize})
